/*Customize Bootsrap for WEOLL*/

$primary: #004D71 !default;
$secondary: #004D71 !default;
$success: #198754 !default;
// $success: #71b6b7 !default;
$info: #0dcaf0 !default;
$warning: #ffc107 !default;
$danger: #dc3545 !default;
$light: #f8f9fa !default;
$dark: #374151 !default;

$theme-colors: (
	"primary": $primary,
	"secondary": $secondary,
	"success": $success,
	"info": $info,
	"warning": $warning,
	"danger": $danger,
	"light": $light,
	"dark": $dark,
	) !default;


$input-font-size: 12px;
$btn-font-size: 12px;
$btn-font-weight: 500;
$dropdown-font-size: 13px;
$grid-gutter-width: 0.5rem !default;
$progress-bar-bg: var(--primary
);

.ratio-11x4 {
	--bs-aspect-ratio: 36.363636363%;
}

@import "~bootstrap/scss/bootstrap";

a {
	color: inherit;

	&:hover {
		color: inherit;
	}
}

/*** BOOTSTRAP BUTTON VARIANTS ***/

.btn {
	font-size: 13px;
}

.btn-primary {
	background-color: var(--bs-gray-100);
	border-color: var(--bs-gray-100);
	color: var(--primary);
	border: none;

	&:hover {
		background-color: rgba(var(--primary-rgb), 0.9);
		border-color: rgba(var(--primary-rgb), 0.9);
	}

	;

	&:focus:not(:focus-visible) {
		background-color: var(--bs-gray-100);
		color: var(--primary);
	}
}

.btn-secondary {
	color: white;
	background-color: var(--bs-gray-100);
	border-color: var(--bs-gray-100);
	color: var(--secondary);
	border: none;

	&:hover {
		color: white;
		background-color: var(--secondary);
		border-color: var(--secondary);
	}

	;

	&:focus:not(:focus-visible) {
		background-color: var(--bs-gray-100);
		color: var(--secondary);
	}
}

.btn-outline-primary {
	background-color: white;
	border-color: var(--primary);
	color: var(--primary);

	&:hover {
		color: white;
		background-color: var(--primary);
		border-color: var(--primary);
	}

	;

	&:focus:not(:focus-visible) {
		background-color: white;
		border-color: var(--primary);
		color: var(--primary);
	}
}

.btn-outline-secondary {
	background-color: white;
	border-color: var(--secondary);
	color: var(--secondary);

	&:hover {
		color: white;
		background-color: var(--secondary);
		border-color: var(--secondary);
	}

	;

	&:focus:not(:focus-visible) {
		background-color: white;
		border-color: var(--secondary);
		color: var(--secondary);
	}
}

.btn-light {
	color: var(--bs-primary);
	background: none;
	border: none;

	&:focus:not(:focus-visible) {
		background: none;
		color: var(--bs-primary);
	}

	;

	&:hover {
		background-color: var(--bs-primary);
		color: white;
	}

	;
}

.btn-outline-light {
	border: 1px solid var(--bs-primary);
	color: var(--bs-primary);
	background-color: white;

	&:hover {
		background-color: var(--bs-primary);
		color: white;
	}

	&:focus:not(:focus-visible) {
		border-color: var(--bs-primary);
	}
}

/*** BOOTSTRAP BUTTON VARIANTS ***/


/*** WEOLL BUTTONS VARIANTS ***/

/* change class name */
.btn-weoll-icon {
	color: #45464E;
	font-weight: 500;
	text-decoration: none;
	padding: 0 !important;

	&:hover {
		color: var(--secondary);
		// background-color: var(--bs-gray-100);
		padding: 0 !important;
	}

	&:focus:not(:focus-visible) {
		color: var(--secondary);
		border: none;
		box-shadow: none !important;
		outline: none !important;
		padding: 0 !important;
	}
}

.btn-weoll-primary {
	background-color: var(--primary);
	color: var(--bs-white);
	border: none;

	&:hover {
		color: var(--bs-white) !important;
		background-color: rgba(var(--primary-rgb), 0.8);
	}

	&:disabled {
		color: var(--bs-white) !important;
		background-color: var(--primary);
		opacity: 0.4;
	}

	&:focus-visible {
		background-color: var(--primary);
		color: var(--bs-white);
		outline: 1px solid var(--primary);
		outline-offset: 2px;
	}

	&:focus:not(:focus-visible) {
		background-color: var(--primary);
		color: var(--bs-white);
		border: none;
	}
}

.btn-weoll-primary-soft {
	background-color: rgba(var(--primary-rgb), 0.1);
	color: var(--primary);
	border: none;

	&:hover {
		color: var(--bs-white) !important;
		background-color: var(--primary) !important;
	}

	&:disabled {
		color: var(--primary) !important;
		background-color: rgba(var(--primary-rgb), 0.2);
		opacity: 0.4;
	}
}

.btn-weoll-outline-primary {
	background-color: var(--bs-white);
	border-color: var(--primary);
	color: var(--primary);

	&:hover {
		color: var(--bs-white) !important;
		background-color: var(--primary) !important;
		border-color: var(--primary);
	}

	&:disabled {
		background-color: var(--bs-white) !important;
		color: var(--bs-gray-600) !important;
		border-color: var(--bs-gray-600) !important;
	}
}

.btn-weoll-outline-primary-soft {
	background-color: rgba(var(--primary-rgb), 0.1);
	color: var(--primary);
	border-color: var(--primary);

	&:hover {
		color: var(--bs-white) !important;
		background-color: var(--primary) !important;
	}

	&:disabled {
		color: var(--primary) !important;
		background-color: rgba(var(--primary-rgb), 0.2);
		opacity: 0.4;
	}
}

.btn-weoll-secondary {
	background-color: var(--secondary);
	color: var(--bs-white);
	border: none;

	&:hover {
		color: var(--bs-white) !important;
		background-color: rgba(var(--secondary-rgb), 0.8) !important;
	}

	&:disabled {
		color: var(--bs-white) !important;
		background-color: var(--secondary);
		opacity: 0.4;
	}
}

.btn-weoll-secondary-soft {
	background-color: rgba(var(--secondary-rgb), 0.1);
	color: var(--secondary);
	border: none;

	&:hover {
		color: var(--bs-white) !important;
		background-color: var(--secondary) !important;
	}

	&:disabled {
		color: var(--secondary) !important;
		background-color: rgba(var(--secondary-rgb), 0.2);
		opacity: 0.4;
	}
}

.btn-weoll-outline-secondary {
	background-color: var(--bs-white);
	border-color: var(--secondary);
	color: var(--secondary);

	&:hover {
		color: var(--bs-white) !important;
		background-color: var(--secondary) !important;
		border-color: var(--secondary);
	}

	&:disabled {
		background-color: var(--bs-white) !important;
		color: var(--bs-gray-600) !important;
		border-color: var(--bs-gray-600) !important;
	}
}

.btn-weoll-outline-secondary-soft {
	background-color: rgba(var(--secondary-rgb), 0.1);
	color: var(--secondary);
	border-color: var(--secondary);

	&:hover {
		color: var(--bs-white) !important;
		background-color: var(--secondary) !important;
	}

	&:disabled {
		color: var(--secondary) !important;
		background-color: rgba(var(--secondary-rgb), 0.2);
		opacity: 0.4;
	}
}

.btn-weoll-warning {
	background-color: var(--bs-warning);
	color: var(--bs-white);
	border: none;

	&:hover {
		color: var(--bs-white) !important;
		background-color: rgba(var(--bs-warning-rgb), 0.8) !important;
	}

	&:disabled {
		color: var(--bs-white) !important;
		background-color: var(--bs-warning);
		opacity: 0.4;
	}
}

.btn-weoll-outline-warning {
	background-color: var(--bs-white);
	border-color: var(--bs-warning);
	color: var(--bs-warning);

	&:hover {
		color: var(--bs-white) !important;
		background-color: var(--bs-warning) !important;
		border-color: var(--bs-warning);
	}

	&:disabled {
		background-color: var(--bs-white) !important;
		color: var(--bs-gray-600) !important;
		border-color: var(--bs-gray-600) !important;
	}
}

// DARK & LIGHT BUTTONS START

.btn-weoll-dark {
	background-color: var(--bs-dark);
	color: var(--bs-white);
	border: none;

	&:hover {
		color: var(--bs-white) !important;
		background-color: rgba(var(--bs-dark-rgb), 0.8) !important;
	}

	&:disabled {
		background-color: var(--bs-dark);
		color: var(--bs-white);
		opacity: 0.4;
	}
}

.btn-weoll-outline-dark {
	background-color: var(--bs-white);
	color: var(--bs-dark);
	border: 1px solid var(--bs-dark);

	&:hover {
		color: var(--bs-white) !important;
		background-color: var(--bs-dark) !important;
	}

	&:disabled {
		background-color: var(--bs-white) !important;
		color: var(--bs-gray-600) !important;
		border-color: var(--bs-gray-600) !important;
	}
}

.btn-weoll-light {
	background-color: var(--bs-white);
	// border-color: var(--primary);
	border: none;
	color: var(--primary);

	&:hover {
		color: var(--bs-white) !important;
		background-color: var(--primary) !important;
		border-color: var(--primary);
	}

	&:disabled {
		background-color: var(--bs-dark);
		color: var(--bs-white);
		opacity: 0.4;
	}
}

.btn-weoll-outline-light {
	background-color: var(--bs-white);
	border-color: var(--bs-dark);
	color: var(--primary);

	&:hover {
		color: var(--bs-white) !important;
		background-color: var(--primary) !important;
		border-color: var(--primary);
	}

	&:disabled {
		background-color: var(--bs-white) !important;
		color: var(--bs-gray-600) !important;
		border-color: var(--bs-gray-600) !important;
	}
}

.btn-weoll-icon-primary {
	background-color: var(--primary);
	border: none;
	color: var(--bs-white);
	padding-left: 6px !important;
	padding-right: 6px !important;

	&:hover {
		color: var(--bs-white) !important;
		background-color: rgba(var(--primary-rgb), 0.8) !important;
	}

	&:disabled {
		color: var(--bs-white) !important;
		background-color: var(--primary);
		opacity: 0.4;
	}
}

.btn-weoll-icon-light {
	background-color: transparent;
	border: none;
	color: var(--bs-dark);
	padding-left: 6px !important;
	padding-right: 6px !important;

	&:hover {
		background-color: rgba(var(--primary-rgb), 0.1) !important;
		color: var(--primary) !important;
		border: none;
	}

	&:disabled {
		color: var(--bs-gray) !important;
	}
}

.btn-weoll-link-light {
	background-color: transparent;
	border: none;
	color: var(--bs-dark);

	&:hover {
		background-color: rgba(var(--primary-rgb), 0.1) !important;
		color: var(--primary) !important;
		border: none;
	}

	&:disabled {
		color: var(--bs-gray) !important;
	}
}

.btn-weoll-link-primary {
	background-color: var(--primary);
	border: none;
	color: var(--bs-white);

	&:hover {
		color: var(--bs-white) !important;
		background-color: rgba(var(--primary-rgb), 0.8) !important;
	}

	&:disabled {
		color: var(--bs-white) !important;
		background-color: var(--primary);
		opacity: 0.4;
	}
}

.btn-weoll-close-button {
	padding: 0 !important;
	border: none;
	color: #DBDBDB;

	&:hover {
		color: var(--bs-black) !important;
	}

	// &:disabled {
	//   color: var(--bs-white) !important;
	// }
}

/*** WEOLL BUTTONS VARIANTS ***/

/* FLOAT BUTTON FOR SMALL SCREEN */

@media only screen and (max-width: 991px) {
	.float-button-s {
		position: fixed;
		bottom: 16px;
		right: 16px;
	}
}

/* FLOAT BUTTON FOR SMALL SCREEN */

.btn-link {
	color: var(--secondary);
	font-weight: 500;
	text-decoration: none;

	&:hover {
		color: var(--secondary) !important;
		background-color: var(--bs-gray-100) !important;
	}

	&:focus:not(:focus-visible) {
		color: var(--secondary);
	}
}

/* INPUT STYLES */

.form-label,
.col-form-label {
	color: var(--bs-gray-600) !important;
	font-weight: 500 !important;
	font-size: 14px !important;
	line-height: 19px !important;
	padding: 4px 0 !important;
	margin: 0 !important;
}

.form-check-label {
	// form-check-label m-auto
	display: flex;
	gap: 4px;
}

.form-control {
	font-size: 14px !important;
	color: var(--bs-gray-700) !important;
	padding: 5px 12px 5px 1rem;
	line-height: 20px !important;
	// @include border-sm;

	&:focus {
		box-shadow: unset;
		outline: 0 none;
		border: 1px solid var(--primary);
	}

	&::placeholder {
		color: var(--bs-gray-500) !important;
		font-size: 14px !important;
	}
}


/* tooltip css */

.fa-question-circle:before {
	font-family: 'Material Design Icons' !important;
	content: '\F02FD' !important;
	font-size: 16px !important;
	line-height: 19px !important;
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--bs-gray-500);
}

.form-check-label>.fa.fa-question-circle {
	font-family: 'Material Design Icons' !important;
	content: '\F02FD' !important;
	font-size: 16px !important;
	line-height: 23px !important;
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--bs-gray-500);
}

.form-select {
	border-color: var(--bs-gray-600);
	font-size: 14px !important;
	color: var(--bs-gray-700);
	padding: 5px 12px 5px 1rem;
	line-height: 20px !important;
	background-image: url("data:image/svg+xml,%3Csvg width='13' height='8' viewBox='0 0 13 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.11508 0.295078L0.705078 1.70508L6.70508 7.70508L12.7051 1.70508L11.2951 0.295078L6.70508 4.87508L2.11508 0.295078Z' fill='%236C757D'/%3E%3C/svg%3E%0A");
	background-size: 12px 7.41px;
	background-position: right 1rem center;

	&:focus {
		box-shadow: inset 0px 0px 0px 3px rgba(var(--primary-rgb), 0.2);
		outline: 0 none;
		border: 1px solid var(--primary);
		background-image: url("data:image/svg+xml,%3Csvg width='13' height='8' viewBox='0 0 13 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.8849 7.70492L12.2949 6.29492L6.29492 0.294922L0.294922 6.29492L1.70492 7.70492L6.29492 3.12492L10.8849 7.70492Z' fill='%236C757D'/%3E%3C/svg%3E%0A");
	}

	&:disabled {
		border-color: var(--bs-gray-400);
		background-image: url("data:image/svg+xml,%3Csvg width='13' height='8' viewBox='0 0 13 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.11508 0.295078L0.705078 1.70508L6.70508 7.70508L12.7051 1.70508L11.2951 0.295078L6.70508 4.87508L2.11508 0.295078Z' fill='%23ADB5BD'/%3E%3C/svg%3E%0A");
	}
}

/* ERRORS */
.is-invalid,
.invalid-feedback {
	font-size: 12px !important;
	font-weight: 300 !important;
	border-color: #D9214E !important;
	display: block;
	width: 100%;
}

.form-control:invalid,
.form-control.is-invalid {
	font-size: 14px !important;
	border-color: #D9214E !important;
	background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.2 12.4H10.8V14H9.2V12.4ZM9.2 6H10.8V10.8H9.2V6ZM10 2C5.576 2 2 5.6 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.08601 16.3997 5.96793 16.989 6.93853 17.391C7.90914 17.7931 8.94943 18 10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 8.94943 17.7931 7.90914 17.391 6.93853C16.989 5.96793 16.3997 5.08601 15.6569 4.34315C14.914 3.60028 14.0321 3.011 13.0615 2.60896C12.0909 2.20693 11.0506 2 10 2ZM10 16.4C8.30261 16.4 6.67475 15.7257 5.47452 14.5255C4.27428 13.3253 3.6 11.6974 3.6 10C3.6 8.30261 4.27428 6.67475 5.47452 5.47452C6.67475 4.27428 8.30261 3.6 10 3.6C11.6974 3.6 13.3253 4.27428 14.5255 5.47452C15.7257 6.67475 16.4 8.30261 16.4 10C16.4 11.6974 15.7257 13.3253 14.5255 14.5255C13.3253 15.7257 11.6974 16.4 10 16.4Z' fill='%23D9214E'/%3E%3C/svg%3E%0A");
	background-repeat: no-repeat;
	background-position: right 12px center;
	background-size: 1rem;
}

.form-select:disabled {
	color: var(--bs-gray-500);
}

.form-select:invalid,
.form-select.is-invalid {
	font-size: 14px !important;
	border-color: #D9214E !important;
}

.form-control:invalid:focus,
.form-control.is-invalid:focus,
.form-select:invalid:focus,
.form-select.is-invalid:focus {
	box-shadow: inset 0px 0px 0px 3px rgba(217, 33, 78, 0.2);
}



.btn:focus {
	box-shadow: none !important;
}
.input-icon {
	padding: 5px 12px 5px 44px !important;
}

.dropdown-toggle::after,
.dropdown-toggle::before {
	display: none !important;
}