.spinner-logo {
    -webkit-animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
    animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
    display: inline-block;
    height: var(--bs-spinner-height);
    vertical-align: var(--bs-spinner-vertical-align);
    width: var(--bs-spinner-width);
}

.logoAnim {
    width: 72px;
    height: 72px;
    animation: crescendo 1s alternate infinite ease-in;
}

@keyframes crescendo {
    0% {
        transform: scale(.8);
    }

    100% {
        transform: scale(1);
    }
}

@-webkit-keyframes spinner-logo {
    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn);
    }
}

@keyframes spinner-logo {
    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn);
    }
}

.spinner-logo {
    --bs-spinner-width: 60px;
    --bs-spinner-height: 60px;
    --bs-spinner-animation-speed: 1.40s;
    --bs-spinner-animation-name: spinner-logo;
}

@media (prefers-reduced-motion: reduce) {
    .spinner-logo {
        --bs-spinner-animation-speed: 1.5s;
    }
}